import agustinLemes from "./agustinLemes.jpg";
import eliseoImperial from "./eliseoImperial.jpg";
import tomasSanchez from "./tomasSanchez.jpg";

const contributors = {
  agustinLemes,
  eliseoImperial,
  tomasSanchez,
};

export default contributors;
