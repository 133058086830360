import a from "./a.png";
import b from "./b.png";
import c from "./c.png";
import d from "./d.png";
import e from "./e.png";
import g from "./g.png";
import ga from "./ga.png";
import gb from "./gb.png";
import h from "./h.png";
import i from "./i.png";

const about = {
  a,
  b,
  c,
  d,
  e,
  g,
  ga,
  gb,
  h,
  i,
};

export default about;
