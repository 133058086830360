import React from "react";
//import Carousel from "../components/Carousel/Carousel";
import BrandsList from "../components/BrandsList/BrandsList";

const Home = () => {
  return (
    <>
      <BrandsList />
    </>
  );
};

export default Home;
